import React from 'react'
import styled from 'styled-components'
import Route from '../images/Route.svg'
import { IphoneXR } from '../responsive'

const Container = styled.div`
    position: relative;
    height: auto;
    background-color: #181818;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${IphoneXR({
        height: "auto",
    })}
`
const TopText = styled.p`
    position: relative;
    top: 0;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.875rem;
    margin-top: 0px;
    padding-top: 20px;
    color: #ffffff;
    ${IphoneXR({
        fontSize: "0.5rem",
        lineHeight: "1rem",
    })}
`
const RouteBox = styled.div`
    position: relative;
    //border: 1px solid white;
    background-color: #1F1F1F;
    border-radius: 33px;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
    width: 70%;
    height: auto;
    background-image: url(${Route});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 20px;
    background-position-x: 60%;
    display: flex;
    flex-direction: column;
    padding-top: 15%;
    padding-bottom: 15%;
    ${IphoneXR({
        height: "auto",
        width: "90%"
})}
`
const KnowDiv = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const ExperienceDiv = styled.div`
    width: 40%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    margin-left: 61%;
    margin-top: 2%;
    //border: 1px solid white;
`
const VisitDiv = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const KnowText = styled.h1`
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 2.5rem;
    color: #ffffff;
    margin-left: 10%;
    text-align: start;
    margin-top: 10%;
    margin-bottom: 0px;
    ${IphoneXR({
        fontSize: "1.25rem",
        lineHeight: "1.875rem",
        marginLeft: "5%",
})}
`
const KnowParagraph = styled.p`
    color: #BEBEBE;
    text-align: start;
    margin-left: 10%;
    font-size: 1.25rem;
    line-height: 1.875rem;
    ${IphoneXR({
        fontSize: "0.5rem",
        lineHeight: "1rem",
        marginLeft: "5%",
})}
`
const StartText = styled.h1`
    color: #F2C94C;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.875rem;
    text-align: start;
    margin-left: 10%;
    margin-top: 0px;
    margin-bottom: 0px;
    ${IphoneXR({
        fontSize: "0.5rem",
        lineHeight: "1rem",
        marginLeft: "5%",
})}
`
const StartTextRight = styled.h1`
     color: #F2C94C;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.875rem;
    text-align: start;
    margin-right: 10%;
    margin-top: 0px;
    margin-bottom: 0px;
    ${IphoneXR({
        fontSize: "0.5rem",
        lineHeight: "1rem",
})}
`

const ExpText = styled.h1`
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 2.5rem;
    color: #ffffff;
    margin-right: 10%;
    text-align: start;
    margin-top: 10%;
    margin-bottom: 0px;
    ${IphoneXR({
        fontSize: "1.25rem",
        lineHeight: "1.875rem",
})}
`
const ExpParagraph = styled.p`
     color: #BEBEBE;
    text-align: start;
    margin-right: 10%;
    font-size: 1.25rem;
    line-height: 1.875rem;
    ${IphoneXR({
        fontSize: "0.5rem",
        lineHeight: "1rem",
})}
`

const HomeSectionTwo = () => {
  return (
    <Container>
        <TopText>
        Get acquainted with everything about your origin,<br/>
        let’s trace the pathway home with you.
        </TopText>
        <RouteBox>
            <KnowDiv>
                <KnowText>Know your <br/> Ancestry</KnowText>
                <KnowParagraph>  
                If you do not know your root, take <br/> an Ancestry DNA test to discover <br/> your heritage.
                </KnowParagraph>
                <StartText>START NOW</StartText>
            </KnowDiv>
            <ExperienceDiv>
                <ExpText>Experience <br/> your culture</ExpText>
                <ExpParagraph>
                Now that you have discovered <br/> your origin, get to know more <br/>  about their music, fashion, food <br/>  and language.
                </ExpParagraph>
                <StartTextRight>START NOW</StartTextRight>
            </ExperienceDiv>
            <VisitDiv>
                <KnowText>Visit Africa <br/> with a grand</KnowText>
                    <KnowParagraph>  
                    Let’s guide you on your trip to <br/> meet the most beautiful people <br/> on the planet.
                </KnowParagraph>
            </VisitDiv>
        </RouteBox>
    </Container>
  )
}

export default HomeSectionTwo