import React from 'react'
import styled from 'styled-components'
import Logo from '../images/PathwayLogo.svg'
import { IphoneXR } from '../responsive'

const Container = styled.div`
    background-color: #181818;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 5%;
`
const FooterContent = styled.div`
    width: 70%;
    height: 60%;
    display: flex;
    ${IphoneXR({
        flexDirection: "column"
    })}
`

const LogoColumn = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: start;
    ${IphoneXR({
        flex: "1.2",
    })}
`
const SupportColumn = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: start;
    ${IphoneXR({
        flex: "1.2",
    })}
`
const AboutColumn = styled.div`
    display: flex;
    flex: 1.5;
    flex-direction: column;
    justify-content: start;
    ${IphoneXR({
        flex: "1.6",
    })}
`
const DetailsColumn = styled.div`
    display: flex;
    flex: 1.5;
    flex-direction: column;
    justify-content: start;
`
const NavLogo = styled.div`
  width: auto;
  height: auto;
  display: flex;
  ${IphoneXR({
        //fontSize: "0.5rem",
        Height: "2rem",
    })}
`
const NavLogoImg = styled.img`
  width: 9vw;
  ${IphoneXR({
        width: "50vw",
    })}
`
const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 50%;
    margin-top: 30px;
`
const Text = styled.p`
    font-weight: 500;
    font-size: 1rem;
    line-height: 3rem;
    color: #ffffff;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: start;
    ${IphoneXR({
        fontSize: "1rem",
        //lineHeight: "0rem",
    })}
`
const SupportText = styled.p`
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: #828282;
    margin: 0px 0px;
    text-align: start;
    ${IphoneXR({
        fontSize: "1rem",
    })}
`
const CopyrightDiv = styled.div`
    margin-top: 20px;
    width: 70%;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const CopyText = styled.p`
    color: #828282;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    text-align: start;
    ${IphoneXR({
        fontSize: "0.4rem",
    })}
`
const LeftCopy = styled.div`

`
const RightCopy = styled.div`

`

const Footer = () => {
  return (
    <Container>
        <FooterContent>
            <LogoColumn>
                <NavLogo>
                    <NavLogoImg src={Logo}/>
                </NavLogo>
                <TextDiv>
                    <Text>Home</Text>
                    <Text>Community</Text>
                    <Text>Become a coach</Text>
                </TextDiv>
            </LogoColumn>
            <SupportColumn>
                <NavLogo>
                    <SupportText>Support</SupportText>
                </NavLogo>
                <TextDiv>
                    <Text>Help Center</Text>
                    <Text>Safety Information</Text>
                    <Text>Privacy terms</Text>
                </TextDiv>
            </SupportColumn>
            <AboutColumn>
                <NavLogo>
                    <SupportText>About</SupportText>
                </NavLogo>
                <TextDiv>
                    <Text>Blog </Text>
                    <Text>Careers</Text>
                    <Text>Pricing</Text>
                </TextDiv>
            </AboutColumn>
            <DetailsColumn>
                <TextDiv>
                    <Text>7480 Mockingbird Hill undefined  </Text>
                    <Text>(239) 555-0108</Text>
                    <Text>Pricing</Text>
                </TextDiv>
            </DetailsColumn>
        </FooterContent>
        <CopyrightDiv>
            <LeftCopy>
                <CopyText>© 2022 Pathway  &nbsp;&nbsp;  ·  &nbsp;&nbsp;  Sitemap</CopyText>
            </LeftCopy>
            <RightCopy>
                <CopyText>English (US)  &nbsp;&nbsp; &nbsp;&nbsp; $ USD</CopyText> 
            </RightCopy>
        </CopyrightDiv>
    </Container>
  )
}

export default Footer