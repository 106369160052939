import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Herobg from '../images/Herobg.png'
import Path from '../images/path.png'
import Gradient from '../images/Gradient.svg'
import KnowRoot from '../images/KnowRoot.svg'
import Treasure from '../images/Treasure.png'
import LeftLeaf from '../images/LeftLeaf.png'
import RightLeaf from '../images/RightLeaf.png'
import Logo from '../images/PathwayLogo.svg'
import { IphoneXR } from '../responsive' 
//import { findByLabelText } from '@testing-library/react'
import { FaBars } from 'react-icons/fa'
import { FaTimes } from 'react-icons/fa'


const Container = styled.div`
    height: 100vh;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-image: url(${Herobg}) ;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    ${IphoneXR({ 
      backgroundPosition: "center",
      backgroundSize: "cover",
      height: "100vh",
    })};
`
const PathImg = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: url(${Path});
  background-repeat: no-repeat;
  background-position-x: 41%;
  background-size: contain;
  ${IphoneXR({ 
      backgroundSize: "cover", 
      backgroundPosition: "center",
      backgroundPositionX: "-40px",
    })};
`
const GradientImg = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(179.89deg, rgba(0, 0, 0, 0.22) -5.48%, #181818 87.08%);
  //background-image: url(${Gradient});
`
const KnowRootDiv = styled.div`
  position: absolute;
  width: auto;
  height: auto;
  bottom: 0;
  margin-bottom: 5px;
`
const KnowRootImg = styled.img`
  width: 8vw;
  ${IphoneXR({
    width: "20vw",
  })}
`
const TreasureDiv = styled.div`
  position: absolute;
  width: auto;
  height: auto;
  bottom: 24.5%;
  ${IphoneXR({
    bottom: "42%",
  })}
`
const TreasureImg = styled.img`
  width: 12vw;
`
const CenterDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const HeroTextDiv = styled.div`
  position: relative;
  width: 50%;
  height: 35%;
  display: flex;
  align-items: center;
  align-self: center;
  ${IphoneXR({
    width: "auto",
    height: "auto",
  })}
`
const HeroText = styled.h1`
  font-weight: 600;
  color: #ffffff;
  font-size: 6.188rem;
  line-height: 5.313rem;
  text-align: center;
  ${IphoneXR({
    fontSize: "3rem",
    lineHeight: "4.2rem",
  })}
`
const OptionDiv = styled.div`
  position: relative;
  width: auto;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${IphoneXR({
    position: "relative",
    width: "auto",
    height: "fit-content",
    marginTop: "0px",
    marginBottom: "0px",
    display: "inline"
    //border: "1px solid white",
  })}

`
const Option = styled.h1`
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 10px;
  align-self: center;
  color: #ffffff;
  ${IphoneXR({
    fontSize: "1rem",
    lineHeight: "1rem",
    marginTop: "0px",
    marginBottom: "10px",
    //border: "1px solid white", 
  })}
`
const OptionDotDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding-bottom: 15px;
  margin: 0 4%;
  ${IphoneXR({
      paddingBottom: "18px",
        //border: "1px solid white",
        height: "5%",
        display: "none"
    })}
`
const OptionDot = styled.h1`
  font-weight: 400;
  color: #ffffff;
  ${IphoneXR({
    fontWeight: "100",
  })}
`

const LeftLeafImg = styled.img`
  position: absolute;
  left: 0;
  margin-left: 6%;
  width: 12vw;
  ${IphoneXR({
    marginLeft: "3%",
  })}
`
const RightLeafImg = styled.img`
  position: absolute;
  right: 0;
  margin-bottom: 8%;
  margin-right: 22%;
  width: 12vw;
  ${IphoneXR({
    marginRight: "15%",
    marginBottom: "23%",
  })}
`
const NavBarBlack = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 10;
  background-color: #181818;
  transition: all 2s ease;
  ${IphoneXR({
    display: "none",
    zIndex: "10",
    position: "fixed",
  })}
`

const NavBar = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 10;
  ${IphoneXR({
    display: "flex",
    justifyContent: "center",
    zIndex: "10",
    position: "fixed",
  })}
`

const Nav = styled.div`
  width: 70%;
  position: fixed;  
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${IphoneXR({
    position: "fixed",
    width: "90%",
    justifyContent: "space-between",
  })}
`
const NavLogo = styled.div`
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  ${IphoneXR({
    display: "flex",
  })}
`
const NavLogoImg = styled.img`
  width: 9vw;
  ${IphoneXR({
    width: "40vw",
    marginLeft: "50%",
  })}
`

const NavMenuDiv = styled.div`
  width: 60%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  ${IphoneXR({
    display: "none",
  })}
`
const NavMenu = styled.h1`
  font-weight: 500;
  color: #ffffff;
  font-size: 0.875rem;
  margin: 0 20px;
  ${IphoneXR({
    fontWeight: "500",
    color: "#ffffff",
    fontSize: "0.875rem",
    marginTop: "15px",

  })}
`

const NavLogin = styled.div`
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  ${IphoneXR({
    justifyContent: "end",
  })}
`
const Bars = styled.div`
  display: none;
  ${IphoneXR({
    display: "flex",
  })}
`

const LoginBtn = styled.button`
  width: 119px;
  height: 42px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  background-color: transparent;
  color: #ffffff;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.2px;
  ${IphoneXR({
    display: "none",
  })}
`
const SideBar = styled.div`
    display: none;
  ${IphoneXR({
    position: "fixed",
    transiton: "width 2s",
    width: "50%",
    height: "100vh",
    right: "0",
    zIndex: "15",
    backgroundColor: "#181818",
    display: "flex",
    flexDirection: "column",
    transform: "translateX(0%)",
    transition: "all 2s ease out",
  })}
`
const TimesDiv = styled.div`
  ${IphoneXR({
    position: "absolute",
    top: "0",
    left: "0",
    marginLeft: "5%",
    marginTop: "5%",
  })}
`
const SideBarMenuOne = styled.div`
  ${IphoneXR({
    //border: "1px solid white",
    width: "100%",
    height: "6%",
    marginTop: "20%",
    justifyContent: "start",
    alignItems: "center",
  })}
`
const SideBarMenu = styled.div`
  ${IphoneXR({
    //border: "1px solid white",
    width: "100%",
    height: "6%",
    justifyContent: "start",
    alignItems: "center",
  })}
`
const SidebarBtn = styled.button`
  ${IphoneXR({
    width: "90%",
    height: "5%",
    border: "1px solid #ffffff",
    borderRadius: "10px",
    backgroundColor: "transparent",
    color: "#ffffff",
    fontWeight: "500",
    fontSize: "1rem",
    letterSpacing: "0.2px",
    margin: "0px auto",
  })}
`


const Hero = () => {

  const [navColor, setNavColor] = useState(false)
  
  const [hideSidebar, setHideSidebar] = useState(true)

  const changeBackground = () => {
    console.log(window.scrollY)
    console.log(window.innerWidth)
    console.log(window.innerHeight)
    if (window.scrollY >= window.innerHeight ) {
      setNavColor(false)
    } else {
      setNavColor(true) 
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })

  return (
    <Container>
        <PathImg>

        </PathImg>
        <GradientImg>

        </GradientImg>
        <KnowRootDiv>
          <KnowRootImg src={KnowRoot} />
        </KnowRootDiv>
        <TreasureDiv>
          <TreasureImg src={Treasure}/>
        </TreasureDiv>
        <CenterDiv>
          <HeroTextDiv>
            <HeroText>Discover <br/> Your Pathway</HeroText>
          </HeroTextDiv>
          <OptionDiv>
              <Option>Food</Option>
              <OptionDotDiv>
                <OptionDot>.</OptionDot>
              </OptionDotDiv>
              <Option>Entertainment</Option>
              <OptionDotDiv>
                <OptionDot>.</OptionDot>
              </OptionDotDiv>
              <Option>Fashion</Option>
              <OptionDotDiv>
                <OptionDot>.</OptionDot>
              </OptionDotDiv>
              <Option>Language</Option>
              <OptionDotDiv>
                <OptionDot>.</OptionDot>
              </OptionDotDiv>
              <Option>Travel</Option>
          </OptionDiv>
          <LeftLeafImg src={LeftLeaf} />
          <RightLeafImg src={RightLeaf} />
        </CenterDiv>
        
        { navColor ? <NavBar>
         <Nav>
            <NavLogo>
              <NavLogoImg src={Logo}/>
            </NavLogo>
            <NavMenuDiv>
              <NavMenu>Your Pathway</NavMenu>
              <NavMenu>About</NavMenu>
              <NavMenu>Blog</NavMenu>
              <NavMenu>Contact</NavMenu>
            </NavMenuDiv>
            <NavLogin>
              <LoginBtn>
                Login
              </LoginBtn>
              <Bars><FaBars color = "#ffffff" size = "1.5rem"  onClick={() => setHideSidebar(!hideSidebar)} /></Bars>
            </NavLogin>
        </Nav>
        </NavBar> :
        <NavBarBlack>
          <Nav>
            <NavLogo>
              <NavLogoImg src={Logo}/>
            </NavLogo>
            <NavMenuDiv>
              <NavMenu>Your Pathway</NavMenu>
              <NavMenu>About</NavMenu>
              <NavMenu>Blog</NavMenu>
              <NavMenu>Contact</NavMenu>
            </NavMenuDiv>
            <NavLogin>
              <LoginBtn>
                Login
              </LoginBtn>
            </NavLogin>
        </Nav>
        </NavBarBlack> } 
        {hideSidebar || <SideBar >
            <TimesDiv>
              <FaTimes color = "#ffffff" size = "1.2rem" onClick={() => setHideSidebar(!hideSidebar)} />
            </TimesDiv>
            <SideBarMenuOne>
              <NavMenu>Your Pathway</NavMenu>
            </SideBarMenuOne>
            <SideBarMenu>
              <NavMenu>About</NavMenu>
            </SideBarMenu>
            <SideBarMenu>
              <NavMenu>Blog</NavMenu>
            </SideBarMenu>
            <SideBarMenu>
              <NavMenu>Contact</NavMenu>
            </SideBarMenu>
            <SidebarBtn>
              Login
            </SidebarBtn>
        </SideBar> } 
    </Container> 
  )
}

export default Hero