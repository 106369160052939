import React from 'react'
import styled from 'styled-components'
import Ballon from '../images/Ballon.png'
import Logo from '../images/PathwayLogo.svg'
import WelcomeBack from '../images/WelcomeBack.svg'
import Facebook from '../images/Facebook.svg'
import Google from '../images/Google.svg'
import { IphoneXR } from '../responsive'

const Container = styled.div`
  height: 150vh;
  display: flex;
`
const ContentDiv = styled.div`
  width: 58%;
  border: 1px solid black;
  background-color: #181818;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 3%;
  display: flex;
  flex-direction: column;
`

const ImgDiv = styled.div`
  width: 42%;
  background-image: url(${Ballon}); 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`
const PathwayLogo = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  padding-left: 10px;
  ${IphoneXR({
    paddingLeft: "0px",
  })}
`
const NavLogoImg = styled.img`
  width: 9vw;
  ${IphoneXR({
    width: "25vw",
  })}
`
const WelcomeDiv = styled.div`
  height: auto;
  display: flex;
  justify-content: start;
  margin-top: 7%;
  ${IphoneXR({
    paddingLeft: "0px",
  })}
`
const WelcomeImg = styled.img`
  width: 16vw;
  ${IphoneXR({
    width: "25vw",
    //border: "1px solid white",
  })}
`
const IntroText = styled.p`
  color: #BEBEBE;
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 400;
  text-align: start;
  ${IphoneXR({
    fontSize: "1rem",
    lineHeight: "1.5rem",
  })}
`
const NameRow = styled.div`
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
  
`
const PasswordRow = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${IphoneXR({
    display: "flex",
    flexDirection: "column",
  })}
`

const LabelAndInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`
const Label = styled.p`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 500;
  text-align: start;
  color: #ffffff;
  margin-top: 0px;
  margin-bottom: 4px;
  ${IphoneXR({
    fontSize: "0.8rem",
    lineHeight: "1rem", 
  })}
`
const NameInput = styled.input`
  background-color: #1F1F1F;
  width: 85%;
  height: 50px;
  border-radius: 8px;
  border: none;
  outline: none;
  color: #838383;
  padding-left: 20px;
`
const EmailRow = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  margin-bottom: 5%;
`

const EmailInput = styled.input`
  background-color: #1F1F1F;
  border-radius: 8px;
  width: 92.5%;
  height: 50px;
  border: none;
  outline: none;
  color: #838383;
  padding-left: 20px;
`
const TandC = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: start;
  color: #BEBEBE;
`
const ColoredText = styled.span`
  color: #F2C94C;
`
const Button = styled.button`
  width: 30%;
  padding-top: 3%;
  padding-bottom: 3%;
  background: #F2C94C;
  border-radius: 10px;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
  border: none;
  color: #333333;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 5%;
  cursor: pointer;
  ${IphoneXR({
    width: "80%", 
    padding: "5% 5%",
  })}
`
const AlreadyText = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  text-align: start;
`
const OrText = styled.h1`
  color: #828282;
  font-size: 26px;
  line-height: 39px;
  font-weight: 400;
  text-align: start;
  margin-top: 1%;
  margin-bottom: 4%;
`
const GoogleBtn = styled.button`
  border: 1.4px solid #828282;
  width: 40%;
  height: 50px;
  border-radius: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${IphoneXR({
    width: "100%",
    marginBottom: "5%",
  })}
`
const FacebookBtn = styled.button`
  border: 1.4px solid #828282;
  width: 40%;
  height: 50px;
  border-radius: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${IphoneXR({
    width: "100%",
    marginTop: "5%",
  })}
`
const LastRow = styled.div`
  height: auto;
  display: flex;
  justify-content: space-between;
  width: 96%;
  ${IphoneXR({
    display: "flex",
    flexDirection: "column",
  })}
`
const GoogleLogo = styled.img`
  width: 15%;
  margin-right: 10px;
`
const FacebookLogo = styled.img`
  width: 15%;
  margin-right: 10px;
`
const BtnText = styled.p`
  color: #E0E0E0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`

const Signin = () => {
  return (
    <Container>
      <ContentDiv>
        <PathwayLogo>
          <NavLogoImg src={Logo} />
        </PathwayLogo>
        <WelcomeDiv>
          <WelcomeImg src={WelcomeBack}/>
        </WelcomeDiv>
        <IntroText>Proceed to your dashboard</IntroText>
        
        <EmailRow>
          <Label>Email</Label>
          <EmailInput type="email" name="email" placeholder='Enter your Email here'/>
        </EmailRow>
        
        <PasswordRow>
        <Label>Password</Label>
          <EmailInput type="password" name="password" placeholder='Enter your Password'/>
        </PasswordRow>
        <Button>Log in</Button>
        <AlreadyText>Not a member?<ColoredText>Create an account for free</ColoredText> </AlreadyText>
        <OrText>OR</OrText>
        <LastRow>
            <GoogleBtn>
              <GoogleLogo src={Google}/>
              <BtnText>Sign up with Google</BtnText>
            </GoogleBtn>
            <FacebookBtn >
              <FacebookLogo src={Facebook} />
              <BtnText>Sign up with Facebook</BtnText>
            </FacebookBtn>
        </LastRow>
      </ContentDiv>
      <ImgDiv>

      </ImgDiv>
    </Container>
  ) 
}

export default Signin