import React from 'react'
import styled from 'styled-components'
import Pattern from '../images/Pattern.svg'
import { IphoneXR } from '../responsive'

const Container = styled.div`
    position: relative;
    height: auto;
    background-color: #181818;
    ${IphoneXR({
    height: "auto",
  })}
`
const Background = styled.div`
    position: relative;
    width: 100%;
    bottom: 0;
    background-image: url(${Pattern});
    background-position: center;
    background-size: cover;
    background-position-y: 0%;
    
`
const Gradient = styled.div`
    position: relative;
    width: 100%;
    height:100%;
    bottom: 0;
    background-image: linear-gradient(179.94deg, #181818 0%, #181818 96.83%); 
    background-position: center;
    background-size: cover;
`
const ContentDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20%;
  padding-bottom: 20%;
`
const TextDiv = styled.div`
  width: auto;
  height: auto;
`
const Heading = styled.h1`
  font-weight: 400;
  font-size: 3.25rem;
  line-height: 3.875rem;
  color: #ffffff;
  margin: 0 auto;
  ${IphoneXR({
    fontWeight: "bolder",
    fontSize: "1.25rem",
    lineHeight: "1.875rem",
  })}
`
const Paragraph = styled.p`
  width: 45%;
  margin: 60px auto;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #ffffff;
  ${IphoneXR({
    fontSize: "1rem",
    lineHeight: "1.875rem",
  })}
`
const Button = styled.button`
  width: 15%;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  background: #F2C94C;
  border-radius: 10px;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
  border: none;
  color: #333333;
  font-weight: 600;
  font-size: 1rem;
  ${IphoneXR({
    width: "80%",
    paddingTop: "3%",
    paddingBottom: "3%",
  })}
`
const BottomDiv = styled.div`
  position: absolute;
  bottom: 0;
  width: auto;
  height: auto;
`

const HomeSectionOne = () => {
  return (
    <Container>
        <Gradient>
          <Background>
            <ContentDiv>
              <TextDiv>
                <Heading>Discover and Experience <br/> your true African Heritage</Heading>
                <Paragraph>
                The world is evolving and with a combination of racial reckoning, 
                cultural appreciation and a need to gain a sense of belonging, 
                now more than ever, millions of people are excited to experience their 
                culture in new ways. Pathway is a one stop shop program designed by
                Amplify Africa to educate the global black diaspora and the world at
                large about the cultures on the continent of Africa.
                </Paragraph>
                <Button>Begin For Free</Button>
              </TextDiv>
              <BottomDiv>
                <Heading>Everything you need</Heading>
              </BottomDiv>
              </ContentDiv>
          </Background>
          
        </Gradient>
    </Container>
  )
}

export default HomeSectionOne