import React from 'react'
import styled from 'styled-components'
import Nerd from '../images/Nerd.svg'
import Bald from '../images/Bald.svg'
import Beret from '../images/Beret.svg'
import Gele from '../images/Gele.svg'
import Ebony from '../images/Ebony.svg'
import Fresh from '../images/Fresh.svg'
import Cute from '../images/Cute.svg'
import { IphoneXR } from '../responsive'

const Container = styled.div`
    position: relative;
    height: auto;
    background-color: #181818;
    display: flex;
    flex-direction: column;
    padding-top: 15%;
    padding-bottom: 15%;
    ${IphoneXR({
      height: "auto",
  })}
`
const Heading = styled.h1`
  font-weight: 400;
  font-size: 3.25rem;
  line-height: 3.875rem;
  color: #ffffff;
  margin: 0 auto;
  ${IphoneXR({
        fontSize: "1.25rem",
        lineHeight: "1.875rem",
    })}
`
const ImgDiv = styled.div`
  height: 65vh;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 7%;
  margin-bottom: 7%;
  display: flex;
  justify-content: center;
  ${IphoneXR({
    height: "auto",
  })}
`
const ColumnOne = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`
const ColumnTwo = styled.div`
  display: flex;
  flex: 1.5;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`
const ColumnThree = styled.div`
  display: flex;
  flex: 2.5;
  align-items: center;
  justify-content: center;
`
const SmallImgDiv = styled.div`
  width: 129px;
  height: 129px;
  border-radius: 100%; 
  display: flex; 
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${IphoneXR({
        display: "none",
    })}
`
const MediumImgDiv = styled.div`
  width: 190px;
  height: 190px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${IphoneXR({
        display: "none",
    })}
`
const LargeImgDiv = styled.div`
  width: 289px;
  height: 289px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${IphoneXR({
        width: "289px",
        height: "289px",
        border: "none",
        overflow: "hidden",
    })}
`
const SmallImg = styled.img`
  border: none;
`
const MediumImg = styled.img`
  border: none;
`
const LargeImg = styled.img`
  border: none;
`
const Button = styled.button`
  width: 15%;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  background: #F2C94C;
  border-radius: 10px;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
  border: none;
  color: #333333;
  font-weight: 600;
  font-size: 1rem;
  ${IphoneXR({
    width: "80%",
    paddingTop: "3%",
    paddingBottom: "3%",
  })}
`
const ButtonDiv = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
`

const HomeSectionThree = () => {
  return (
    <Container>
      <Heading>Finding your Path is fun with <br/> a Community of like minds</Heading>
      <ImgDiv>
        <ColumnOne>
          <SmallImgDiv>
            <SmallImg src = {Nerd} />
          </SmallImgDiv>
        </ColumnOne>
        <ColumnTwo>
          <MediumImgDiv>
            <MediumImg src = {Bald} />
          </MediumImgDiv>
          <MediumImgDiv>
            <MediumImg src = {Beret} />
          </MediumImgDiv>
        </ColumnTwo>
        <ColumnThree>
          <LargeImgDiv>
            <LargeImg src = {Gele} />
          </LargeImgDiv>
        </ColumnThree>
        <ColumnTwo>
          <MediumImgDiv>
            <MediumImg src={Ebony} />
          </MediumImgDiv>
          <MediumImgDiv>
            <MediumImg src={Fresh} />
          </MediumImgDiv>
        </ColumnTwo>
        <ColumnOne>
          <SmallImgDiv>
            <SmallImg src = {Cute} />
          </SmallImgDiv>
        </ColumnOne>
      </ImgDiv>
      <ButtonDiv>
        <Button>Begin For Free</Button>
      </ButtonDiv>
    </Container>
    
  )
}

export default HomeSectionThree