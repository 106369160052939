import React from 'react'
import styled from 'styled-components';
import Hero from '../components/Hero';
import HomeSectionOne from '../components/HomeSectionOne';
import HomeSectionTwo from '../components/HomeSectionTwo';
import HomeSectionThree from '../components/HomeSectionThree';
import Footer from '../components/Footer';

const Container = styled.div`
  
`
const Home = () => {
  return (
    <Container>
        <Hero />
        <HomeSectionOne />
        <HomeSectionTwo />
        <HomeSectionThree />
        <Footer />
    </Container>
  )
}

export default Home